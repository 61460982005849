import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';

type PillProps = {
    text: string;
    isLive?: boolean;
    color?: string; // tag ; light-tab
};

function Pill({ text, isLive, color = '' }: PillProps) {
    return (
        <IonItem
            color={color}
            className={`eldo-pill typo-b2 regular ${isLive ? 'eldo-pill--rounded is-live' : ''}`}
        >
            <IonLabel className="eldo-pill__label">{text}</IonLabel>
        </IonItem>
    );
}

export default Pill;
