import { useEffect, useRef } from 'react';
import OneSignal from 'react-onesignal';
import CordovaOneSignal from 'onesignal-cordova-plugin';
import { isPlatform } from '@ionic/react';
import { useHistory } from 'react-router';

const appId = process.env.REACT_APP_ONE_SIGNAL_IDENTIFIER || '';
const env = process.env.REACT_APP_ENV || 'dev';

function OneSignalInit(): void {
    if (isPlatform('cordova')) {
        CordovaOneSignal.setAppId(appId);
        CordovaOneSignal.setNotificationOpenedHandler(() => {});

        CordovaOneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {});
    }
}

const useOneSignal = () => {
    const oneSignalInitRef = useRef<boolean>(false);
    useEffect(() => {
        if (!appId || oneSignalInitRef.current) {
            return;
        }

        oneSignalInitRef.current = true;

        if (isPlatform('mobile')) {
            OneSignal.init({
                appId,
                allowLocalhostAsSecureOrigin: env === 'dev',
            });

            try {
                OneSignalInit();
            } catch (err) {
                console.log(err);
            }
        }
    }, []);

    const setExternalUserId = (id: string) => {
        if (isPlatform('cordova')) {
            CordovaOneSignal.setExternalUserId(id);
        } else {
            OneSignal.setExternalUserId(id);
        }
    };

    return { setExternalUserId };
};

export default useOneSignal;
