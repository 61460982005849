import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useState } from 'react';
import { CategorizedReplays, PodcastOrReplay } from 'types';
import { Link, useParams } from 'react-router-dom';
import Title from 'components/Title';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import { isNativeAd } from 'lib/utils';
import PageLayout from 'components/PageLayout';
import useResponsive from 'features/responsive/responsive';
import useReplayByCategory from 'features/podcastsReplays/hooks/useReplayByCategory';
import CardVertical from 'components/core/CardVertical';
import { useTranslation } from 'react-i18next';
import { AdData } from 'features/advertising/generateAdPayload';
import LoadingIndicator from 'components/LoadingIndicator';
import { podcastHiddenIds } from './PodcastReplay';
import AdRightBlock from '../components/Ads/AdRightBlock';
import PageNoData from './PageNoData';

function capitalizeFirstLetter(category: string) {
    return category.charAt(0).toUpperCase() + category.slice(1);
}

function isCategorizedReplays(data: any): data is CategorizedReplays {
    return 'replays' in data;
}

export default function PodcastOrReplayCategorized() {
    const { ads } = useAds({ page: 'podcast-categories' });
    const { category } = useParams<{ category: string }>();
    const { podcastByCategory: data, isLoading } = useReplayByCategory(category, 'podcast-replay-category-native');
    const isEldo4Real = category === 'eldo4real';
    const { t } = useTranslation();
    const title = isEldo4Real
        ? t('eldo4real')
        : (data as CategorizedReplays)?.tag ?? capitalizeFirstLetter(t(category));
    const { IsMobile } = useResponsive();

    const [hiddenAds, setHiddenAds] = useState<string[]>([]);
    const isHiddenAd = (ad: AdData) => hiddenAds.find((h) => h === ad.containerId);
    const hideAd = (ad: AdData) => setHiddenAds((ha) => [...ha, ad.containerId]);

    if (!data && !isLoading) {
        return <PageNoData />;
    }

    return (
        <PageLayout
            title={title}
            hasBackButton
        >
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}
                <IonRow>
                    <IonCol className="p-0 mb-4 md:mb-10">
                        <div className="relative">
                            <div className="absolute right-[-30px] md:hidden">
                                <img
                                    src="./assets/img/team/Ornament-81.png"
                                    alt="ornament"
                                />
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                {!IsMobile && (
                    <Title
                        className="mb-blockInnerMobile md:mb-blockInner"
                        main
                        title={title}
                    />
                )}
                {!isLoading && data ? (
                    <AdRightBlock ad={ads?.data?.[1]}>
                        <IonRow className="grid gap-standardMobile md:gap-standard md:grid-cols-3 mb-[25px]">
                            {(isCategorizedReplays(data)
                                ? data.replays
                                : data.filter(
                                      (podcast) =>
                                          podcast.type !== 'podcast' &&
                                          !podcastHiddenIds.find((id) => id === podcast.id),
                                  )
                            ).map((article: PodcastOrReplay | AdData, index: number) =>
                                !isNativeAd(article) ? (
                                    <Link
                                        key={index}
                                        to={`/podcast-replay/type/${
                                            isEldo4Real ? 'eldo4real' : article.type.toLowerCase()
                                        }/${article.id}`}
                                        className="transform [&:nth-child(3n-1)]:md:translate-y-[25px]"
                                    >
                                        <CardVertical
                                            thumbnailUrl={article.poster ? article.poster : 'cataas.com/cat'}
                                            title={article.title}
                                            tags={article.tags.slice(0, 2)}
                                            imgSize="small"
                                            hasPlayBtnOnTopOfImg
                                            className="md:m-1"
                                        />
                                    </Link>
                                ) : (
                                    article &&
                                    !isHiddenAd(article) && (
                                        <AdComponent
                                            ad={article}
                                            hasSpacings={false}
                                            className="!h-[245px] overflow-hidden mt-block-innerMobile md:mt-block-inner"
                                            onNoContent={() => hideAd(article)}
                                        />
                                    )
                                ),
                            )}
                        </IonRow>
                    </AdRightBlock>
                ) : (
                    <LoadingIndicator />
                )}

                {ads && (
                    <AdComponent
                        ad={ads.data[2]}
                        className="mt-[50px]"
                    />
                )}
            </IonGrid>
        </PageLayout>
    );
}
