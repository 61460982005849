import { IonGrid, IonInput, IonItem, IonLabel, IonRow, useIonToast } from '@ionic/react';
import { ErrorMessage } from '@hookform/error-message';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button';
import * as yup from 'yup';
import { emailRegex } from 'lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { LetzAiFormType } from 'lib/ApiService';
import InputError from 'components/InputError';
import useResponsive from 'features/responsive/responsive';
import PhoneInput from 'components/PhoneInput';
import { CalendarIconEmpty } from 'components/Icons';
import ToggleButton from 'components/ToggleButton';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { TOAST_DURATION } from '../../constants';

type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    policyAccepted: boolean;
    ageConfirmation: boolean;
};

export default function LetzAiForm({ onComplete }: { onComplete: (data: LetzAiFormType) => void }) {
    const { t } = useTranslation();
    const [present] = useIonToast();
    const history = useHistory();

    const { IsMobile } = useResponsive();

    const schema = yup
        .object({
            firstName: yup.string().required(t('letzAi.form.error.firstNameRequired')),
            lastName: yup.string().required(t('letzAi.form.error.lastNameRequired')),
            email: yup
                .string()
                .required(t('letzAi.form.error.emailRequired'))
                .matches(emailRegex, { message: t('letzAi.form.error.emailInvalid') }),
            phone: yup.string().required(t('letzAi.form.error.phoneRequired')),
            policyAccepted: yup
                .boolean()
                .required(t('letzAi.form.error.policyRequired'))
                .oneOf([true], t('letzAi.form.error.policyRequired')),
            ageConfirmation: yup
                .boolean()
                .required(t('letzAi.form.error.ageConfirmationRequired'))
                .oneOf([true], t('letzAi.form.error.ageConfirmationRequired')),
        })
        .required();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { isValid, errors },
        control,
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: FormValues) => {
        onComplete(data);
    };

    return (
        <IonGrid
            fixed
            className="md:flex md:justify-center md:bg-[#141414]"
        >
            <div className="md:w-[350px]">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col gap-[30px]"
                >
                    <div className="grid grid-cols-[min-content_1fr] gap-[16px] mt-[25px]">
                        <CalendarIconEmpty className="size-[27px]" />
                        <span className="regular typo-b1">{moment(new Date()).format('DD.MM.YYYY')}</span>
                    </div>
                    <IonRow>
                        <IonItem className="game-input">
                            <IonLabel
                                className="game-input"
                                position="floating"
                            >
                                {t('letzAi.firstName')}
                            </IonLabel>
                            <IonInput
                                className="game-input"
                                {...register('firstName')}
                                name="firstName"
                            />
                        </IonItem>
                    </IonRow>
                    <IonRow>
                        <IonItem className="game-input">
                            <IonLabel
                                className="game-input"
                                position="floating"
                            >
                                {t('letzAi.lastName')}
                            </IonLabel>
                            <IonInput
                                className="game-input"
                                {...register('lastName')}
                                name="lastName"
                            />
                        </IonItem>
                    </IonRow>
                    <IonRow>
                        <IonItem className="game-input">
                            <IonLabel
                                className="game-input"
                                position="floating"
                            >
                                {t('ticketBourse.email')}
                            </IonLabel>
                            <IonInput
                                className="game-input"
                                {...register('email')}
                                name="email"
                            />
                        </IonItem>
                    </IonRow>
                    <IonRow>
                        <PhoneInput
                            placeholder={t('phone')}
                            {...register('phone')}
                            control={control}
                            name="phone"
                        />
                    </IonRow>
                    <div>
                        <ToggleButton
                            {...register('policyAccepted')}
                            className="my-[15px]"
                            name="policyAccepted"
                            label={
                                <Trans
                                    i18nKey="accept_policies_toggle"
                                    className="typo-b3"
                                >
                                    Agree to
                                    <Link
                                        target="_blank"
                                        to="/terms"
                                        className="text-white underline"
                                    >
                                        Terms of Services
                                    </Link>
                                    and
                                    <Link
                                        target="_blank"
                                        to="/terms"
                                        className="text-white underline"
                                    >
                                        Privacy Policy
                                    </Link>
                                </Trans>
                            }
                        />
                        <ToggleButton
                            {...register('ageConfirmation')}
                            className="my-[15px]"
                            label={t('letzAi.ageConfirmation')}
                            name="ageConfirmation"
                        />
                    </div>

                    <IonRow className="flex flex-col gap-[40px]">
                        <div className="flex flex-col gap-[10px]">
                            <ErrorMessage
                                errors={errors}
                                name="firstName"
                                as={<InputError />}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="phone"
                                as={<InputError />}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                as={<InputError />}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="policyAccepted"
                                as={<InputError />}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="ageConfirmation"
                                as={<InputError />}
                            />
                        </div>
                        <div>
                            <Button
                                value={t('letzAi.send')}
                                className={clsx(
                                    'bg-[#8A8A8A] border-0 hover:bg-[#e5e0e0] transition duration-300',
                                    isValid ? '!text-[#141414] bg-white' : 'text-[#555555]',
                                )}
                                type="submit"
                                disabled={false}
                            />
                        </div>
                    </IonRow>
                </form>
            </div>
        </IonGrid>
    );
}
