import GridLoader from 'react-spinners/GridLoader';

export default function LoadingIndicator() {
    return (
        <div className="w-[60px] mx-auto mt-block-innerMobile md:mt-block-inner mb-blockInnerMobile md:mb-blockInner">
            <GridLoader
                className="flex self-center"
                color="#f08b1f"
                loading
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
}
