import { IonGrid, IonRow } from '@ionic/react';
import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import useNews from 'features/news/hooks/useNews';
import PageLayout from 'components/PageLayout';
import Title from 'components/Title';
import useResponsive from 'features/responsive/responsive';
import LoadingIndicator from 'components/LoadingIndicator';
import CarouselWithAds from 'components/Carousel/CarouselWithAds';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getThumbnailForHorizontalArticleList } from 'components/core/ArticlePageComponent';
import CardVertical from 'components/core/CardVertical';
import { Link } from 'react-router-dom';
import Section from 'components/core/Section';
import { CategorizedReplays, PodcastOrReplay } from 'types';
import useReplayByCategory from 'features/podcastsReplays/hooks/useReplayByCategory';
import AdRightBlock from 'components/Ads/AdRightBlock';
import CardListingChunksWithAds from '../components/CardListingChunksWithAds';
import PageNoData from './PageNoData';

function CategoryListPage({ isCompactView }: CategoryListProps) {
    // change this to the new ads
    const { ads } = useAds({ page: 'category-list-news-replays' });
    const { tag } = useParams<{ tag: string }>();
    const { t } = useTranslation();
    const newsByTag = useNews({ tag });
    const { podcastByCategory, isLoading } = useReplayByCategory(tag);

    const observerTarget = useRef(null);
    const { IsDesktop } = useResponsive();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    newsByTag.loadMore();
                }
            },
            { threshold: 1 },
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, newsByTag]);

    const title = useMemo<string>(() => {
        if (newsByTag?.data?.pages?.length && newsByTag?.data?.pages[0]?.tag) {
            return newsByTag?.data?.pages[0]?.tag;
        }
        return (podcastByCategory as CategorizedReplays)?.tag ?? '';
    }, [newsByTag]);

    if (isLoading || newsByTag.isLoading) {
        return (
            <PageLayout
                title={title}
                cssClass="news-page"
                hasBackButton
            >
                <LoadingIndicator />
            </PageLayout>
        );
    }

    return !newsByTag.isLoading &&
        (newsByTag?.list.length !== 0 || (podcastByCategory as CategorizedReplays)?.replays?.length) ? (
        <PageLayout
            title={title}
            cssClass="news-page"
            hasBackButton
        >
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}
                {IsDesktop && title && (
                    <IonRow className="mb-blockMobile md:mb-block">
                        <Title
                            title={title}
                            main
                        />
                    </IonRow>
                )}
                {(podcastByCategory as CategorizedReplays)?.replays?.length && (
                    <Section
                        title={t('replays')}
                        className=""
                        path={`/podcast-replay/category/${
                            (podcastByCategory as CategorizedReplays)?.tag?.toLowerCase() as unknown as string
                        }`}
                    >
                        <AdRightBlock ad={ads?.data?.[2]}>
                            <CarouselWithAds
                                adNativeClassName="h-[220px] overflow-hidden"
                                // @ts-ignore
                                data={podcastByCategory?.replays || []}
                            >
                                {(replays: PodcastOrReplay) => (
                                    <Link
                                        to={`/podcast-replay/type/${replays?.type?.toLowerCase()}/${
                                            replays.id as unknown as string
                                        }`}
                                        className="block h-full w-full"
                                        key={replays.id}
                                    >
                                        <CardVertical
                                            thumbnailUrl={
                                                replays?.poster ? `${replays?.poster ?? ''}_250x220` : 'cataas.com/cat'
                                            }
                                            title={replays.title}
                                            tags={replays.tags}
                                            footerSlot={
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        {replays.duration
                                                            ? `${parseInt(replays.duration, 10)} ${t('sec')}`
                                                            : ''}
                                                    </div>
                                                </div>
                                            }
                                            className="h-full"
                                            imgSize="medium"
                                            hasPlayBtnOnTopOfImg
                                        />
                                    </Link>
                                )}
                            </CarouselWithAds>
                        </AdRightBlock>
                    </Section>
                )}
                {ads && (podcastByCategory as CategorizedReplays)?.replays?.length && <AdComponent ad={ads.data[1]} />}

                {newsByTag?.list.length !== 0 && (
                    <Section
                        className="mt-[75px]"
                        title={t('news')}
                    >
                        <CardListingChunksWithAds
                            className=""
                            adsBetween={ads ? [ads.data[2]] : []}
                            data={newsByTag?.list || []}
                            adNativeClassName="!h-[200px] overflow-hidden"
                        >
                            {(item) => (
                                <HorizontalMediumCard
                                    title={item.title}
                                    date={item.date}
                                    tags={item.tags ?? []}
                                    thumbnailUrl={getThumbnailForHorizontalArticleList(item)}
                                    truncated={3}
                                    linkForPill="/aktuell/news/"
                                    linkTo={`/kino/articles/${item.id as unknown as string}`}
                                    className="min-h-[170px] md:min-h-[200px]"
                                />
                            )}
                        </CardListingChunksWithAds>

                        {newsByTag.canLoadMore ? (
                            <IonRow className="mb-blockMobile md:mb-block">
                                <div
                                    className="m-auto h-4"
                                    ref={observerTarget}
                                />
                            </IonRow>
                        ) : (
                            ''
                        )}
                    </Section>
                )}
                {ads &&
                    !newsByTag.canLoadMore &&
                    !newsByTag.isLoading &&
                    !(podcastByCategory as CategorizedReplays)?.replays?.length && <AdComponent ad={ads.data[1]} />}
                {ads &&
                    !newsByTag.canLoadMore &&
                    !newsByTag.isLoading &&
                    (podcastByCategory as CategorizedReplays)?.replays?.length && <AdComponent ad={ads.data[3]} />}
            </IonGrid>
        </PageLayout>
    ) : (
        (!newsByTag.isLoading && <PageNoData />) || <div />
    );
}

type CategoryListProps = {
    isCompactView?: boolean;
};

CategoryListPage.defaultProps = {
    isCompactView: false,
};

export default CategoryListPage;
