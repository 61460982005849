import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Title from 'components/Title';
import PageLayout from 'components/PageLayout';
import { IonGrid, useIonRouter, useIonToast } from '@ionic/react';
import useLetzAiApprovedPicture from 'features/letzAi/hooks/useLetzAiApprovedPicture';
import { useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { TOAST_DURATION } from '../../constants';

export default function LetzAiApprovedPicture() {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { data, isLoading } = useLetzAiApprovedPicture(id);
    const [present] = useIonToast();
    const router = useIonRouter();

    return (
        <PageLayout>
            <IonGrid fixed>
                {!isLoading ? (
                    <div className="flex flex-col gap-[40px] w-full relative">
                        <Title
                            title={`${data?.slug as string} Event`}
                            hidePicto
                        />
                        {data?.sponsor && (
                            <img
                                src={data?.sponsor}
                                alt="Sponsor"
                                className="w-[180px] mx-auto"
                            />
                        )}
                        <Title
                            title={t('letzAi.pictureApprovedText')}
                            hidePicto
                            className="mx-auto"
                        />
                        <img
                            src={data?.path}
                            alt="Generated"
                        />

                        <div className="max-w-[350px] md:w-[350px] mx-auto flex flex-col gap-[20px]">
                            <Button
                                value={t('letzAi.copyLinkButton')}
                                className="!text-[#141414] bg-white hover:bg-[#e5e0e0] transition duration-300 border-0"
                                type="submit"
                                onClick={() => {
                                    navigator.clipboard.writeText(window.location.href);
                                    present(t('main.copied_to_clipboard'), TOAST_DURATION);
                                }}
                            />
                            <Button
                                onClick={() => router.push(`/aktiounen/${data?.slug as string}`)}
                                value={t('letzAi.backToEvent')}
                                className="bg-transparent text-white md:w-[350px] w-full"
                            />
                        </div>
                    </div>
                ) : (
                    <LoadingIndicator />
                )}
            </IonGrid>
        </PageLayout>
    );
}
