import React, { useState } from 'react';
import { ReactComponent as ImageFallbackSvg } from 'components/Icons/image_fallback.svg';

export type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    imgClassName?: string;
    // to make this work, add prop 'cardHoverEffect' to the parent card component or className "group/hovercard" to a parent
    hasCardHoverEffect?: boolean;
    // if set to true, remove class 'rounded' from img
    sharp?: boolean;
    // fallbackImage?: string;
};

export default function Img({ imgClassName, hasCardHoverEffect, sharp, ...imgProps }: ImgProps) {
    const [needFallbackImage, setNeedFallbackImage] = useState(false);

    const imgClassNames = React.useMemo(() => {
        let res = imgClassName || '';
        if (hasCardHoverEffect) {
            res += ' transition-all duration-300 transform md:group-hover/hovercard:scale-110';
        }
        if (!sharp) {
            res += ' rounded';
        }
        return res;
    }, [imgClassName]);

    return (
        <div className={`overflow-hidden ${!sharp ? 'rounded' : ''} ${imgProps.className || ''}`}>
            <img
                onError={() => {
                    setNeedFallbackImage(true);
                }}
                alt=""
                {...imgProps}
                src={needFallbackImage ? '/assets/img/placeholderimg2.svg' : imgProps.src}
                className={imgClassNames}
            />
        </div>
    );
}
