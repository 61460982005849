import { useTranslation } from 'react-i18next';
import { TopicCategory } from 'types';
import CardListing from 'components/core/CardListing';
import { generateUniqueId } from 'lib/utils';
import TopicCard from './TopicCard';

type CardProps = {
    data: TopicCategory[] | undefined;
};

function TopicCardList({ data }: CardProps) {
    const { t } = useTranslation();

    if (!data) return null;

    const getRouterLink = (item: TopicCategory) => {
        return item.prefixSlug
            ? `${item.prefixSlug as unknown as string}${item.slug as unknown as string}`
            : `/${item.slug as unknown as string}`;
    };

    const picturesUrl = `${process.env.REACT_APP_API_URL ?? 'https://api-mobile.eldo.lu'}/pictures`;

    return (
        <CardListing>
            {data?.map((item: TopicCategory) => (
                <TopicCard
                    routerLink={getRouterLink(item)}
                    item={item}
                    key={generateUniqueId()}
                />
            ))}
        </CardListing>
    );
}

export default TopicCardList;
