import { IonContent, useIonToast } from '@ionic/react';
import { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { convertObjectToArray, emailRegex } from 'lib/utils';

import Button from 'components/Button';
import Title from 'components/Title';
import { ensureSecureUrl } from 'features/utils/utils';
import Gallery from 'components/Gallery';
import CarouselWithAds from 'components/Carousel/CarouselWithAds';
import useResponsive from 'features/responsive/responsive';
import clsx from 'clsx';

interface LetzAiPictureProps {
    onComplete: (pictureId: string) => void;
    isLoadingPicture: boolean;
    pictures?: string[];
}

export default function LetzAiPicture({ onComplete, isLoadingPicture, pictures }: LetzAiPictureProps) {
    const { t } = useTranslation();
    const { IsMobile } = useResponsive();
    const [currentPicture, setCurrentPicture] = useState(0);

    return isLoadingPicture ? (
        <section className="flex flex-col gap-[25px] items-center min-h-[150px]">
            <article>{t('letzAi.waitForPictureMessage')}</article>
            <article className="letz-ai-loading-indicator" />
        </section>
    ) : (
        <div className="flex flex-col gap-[40px] w-full relative">
            <Title
                title={t('letzAi.pictureGenratedText')}
                hidePicto
            />
            <div className="md:w-[720px] flex flex-col gap-[40px] md:mx-auto mr-[20px]">
                {pictures && pictures.length > 0 && (
                    <CarouselWithAds
                        data={pictures}
                        slidesPerView={1}
                        isFullWithOnMobile
                        autoHeight={false}
                        isGallery
                        onSlideChange={(item) => setCurrentPicture(item.activeIndex)}
                    >
                        {(picture: any) => (
                            <div className="rounded-xl flex items-center swiper-zoom-container">
                                <img
                                    alt="gallery"
                                    className="rounded-xl w-full my-auto"
                                    src={ensureSecureUrl(convertObjectToArray(picture)?.[0].value as string)}
                                />
                            </div>
                        )}
                    </CarouselWithAds>
                )}
                <div className={clsx('m-auto w-full text-center', !IsMobile && 'bottom-0 relative')}>
                    {currentPicture + 1} / {pictures?.length}
                </div>
            </div>

            <div className="max-w-[350px] md:w-[350px] mx-auto">
                <Button
                    value={t('letzAi.submitPicture')}
                    className="!text-[#141414] bg-white hover:bg-[#e5e0e0] transition duration-300 border-0"
                    type="submit"
                    onClick={() => onComplete(convertObjectToArray(pictures?.[currentPicture])[0].key)}
                />
            </div>
        </div>
    );
}
