import { IonContent, isPlatform } from '@ionic/react';
import { App } from '@capacitor/app';
import { shouldSendArticleId } from 'AppRoutes';
import useResponsive from 'features/responsive/responsive';
import useAds from 'hooks/useAds';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getArticle, getEventById, getPodcastReplay } from 'lib/ApiService';
import { Tags } from 'types';
import { ipAktiounen } from 'features/radio/constants';
import { isTopicRoute } from 'lib/utils';
import AdComponent from './AdComponent';

function containsAktiounenPattern() {
    const pattern = /\/aktiounen\/.+/;
    return pattern.test(window.location.pathname);
}

async function getTagsFromArticle(articleId: string) {
    const currentURL = window.location.pathname;

    const newsArticleRoutePattern = /^\/radio\/[^/]+\/a\/[^/]+$/;

    const functionMap = {
        '/aktuell/news/a/': getArticle,
        '/cinema/articles/': getArticle,
        '/podcast-replay/type/replay/': getPodcastReplay,
        '/events/agenda/': getEventById,
        [newsArticleRoutePattern.source]: getArticle,
    };

    const matchingRoute = Object.keys(functionMap).find((route) => {
        const regex = new RegExp(`^${route}[^/]+$`);
        return regex.test(currentURL);
    });

    if (matchingRoute) {
        if (functionMap[matchingRoute] === getArticle) {
            // @ts-ignore
            return functionMap[matchingRoute](articleId).then((data) => data?.tags ?? []);
        }

        if (functionMap[matchingRoute] === getPodcastReplay) {
            // @ts-ignore
            return functionMap[matchingRoute](articleId, 'replay').then((data) => data?.tags ?? []);
        }

        if (functionMap[matchingRoute] === getPodcastReplay) {
            // @ts-ignore
            return functionMap[matchingRoute](articleId, 'replay').then((data) => data?.tags ?? []);
        }
    }

    return [];
}

const MAX_INACTIVE_TIME = 5_000;

export default function SplashScreenAd({ onHide }: { onHide: () => void }) {
    const location = useLocation();
    const articleId = location.pathname.split('/').pop();
    const [isLoading, setIsLoading] = useState(true);
    const [tags, setTags] = useState<Tags[] | undefined>(undefined);
    const { IsMobile } = useResponsive();
    const { ads } = useAds({
        page: 'splash',
        articleId: shouldSendArticleId() ? articleId : '0',
        reloadOnLocationChange: false,
        tags,
        isLoading,
    });

    const [isHidden, setIsHidden] = useState(false);
    const hide = () => {
        setIsHidden(true);
        onHide();
    };

    function display() {
        setIsHidden(false);
    }

    useEffect(() => {
        if (tags !== undefined) {
            setIsLoading(false);
        }
    }, [tags]);

    useEffect(() => {
        if (containsAktiounenPattern()) {
            setTags(() => [ipAktiounen]);
        } else {
            setTags(undefined);
            getTagsFromArticle(articleId ?? '').then((resultTags) => setTags(resultTags as Tags[]));
        }

        let time = 0;
        const listener = App.addListener('appStateChange', ({ isActive }) => {
            if (isActive) {
                if (!time) return;
                if (Date.now() - time > MAX_INACTIVE_TIME && IsMobile) {
                    display();
                }
            } else {
                time = Date.now();
            }
        });

        return () => {
            listener.remove();
        };
    }, [ads, location.pathname]);

    useEffect(() => {
        if (ads && !ads?.data?.length) {
            hide();
        }
    }, [ads]);

    // eslint-disable-next-line no-nested-ternary
    return ads?.data[0] && !isHidden ? (
        <IonContent
            className="z-[999]"
            fullscreen
            style={{ '--padding-top': isPlatform('cordova') && isPlatform('ios') ? '50px' : '0px' }}
        >
            <AdComponent
                ad={ads.data[0]}
                hasSpacings={false}
                splash
                className="!static"
                onNoContent={hide}
                onClosed={hide}
            />
        </IonContent>
    ) : null;
}
