import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { LetzAiError } from 'components/Icons';

interface LetzAiParticiaptedProps {
    onComplete: () => void;
}

export default function LetzAiParticiapted({ onComplete }: LetzAiParticiaptedProps) {
    const { t } = useTranslation();

    return (
        <div className="grid md:place-items-center max-md:h-full">
            <LetzAiError className="max-md:absolute max-md:top-1/3 max-md:left-1/2 max-md:transform max-md:-translate-x-1/2 max-md:-translate-y-1/2" />
            <div className="mt-12.5 h-fit grid place-items-center max-md:absolute max-md:bottom-12.5">
                <div className="typo-h2 bold">{t('letzAi.letzAiError')}</div>
                <div className="w-fit mt-7.5">
                    <Button
                        onClick={onComplete}
                        value={t('letzAi.startAgain')}
                        className="bg-transparent text-white md:w-[350px] w-full"
                    />
                </div>
            </div>
        </div>
    );
}
