import { MouseEventHandler, useEffect, useState } from 'react';
import { IonLoading, IonRouterLink, isPlatform, useIonToast } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import { emailRegex } from 'lib/utils';
import { getFacebookUrl, getTiktokUrl } from 'lib/ApiService';
import useResponsive from 'features/responsive/responsive';
import loginIllustration from './Icons/login.svg';
import Input from './Input';
import InputError from './InputError';
import Button from './Button';
import useAuth from '../features/auth/useAuth';

import { ReactComponent as FacebookIcon } from './Icons/facebook.svg';
import { ReactComponent as InstaIcon } from './Icons/instagram.svg';
import { ReactComponent as TiktokIcon } from './Icons/tiktok.svg';
import OtpForm from './OtpForm';

type LoginFormValues = {
    email: string;
    password: string;
};

type LoginFormProps = {
    className?: string;
    onDone?: () => void;
    onClose?: () => void;
    onForgotPasswordClick?: () => void;
    onSignupClick?: () => void;
};

function NetworkLoginButton({ children, onClick }: { children: React.ReactNode; onClick: MouseEventHandler }) {
    return (
        <div
            className="w-[72px] h-[45px] grid place-items-center bg-white rounded-[100px] cursor-pointer"
            onClick={onClick}
        >
            {children}
        </div>
    );
}

export default function LoginForm({
    className,
    onDone,
    onClose,
    onForgotPasswordClick,
    onSignupClick,
}: LoginFormProps) {
    const { t } = useTranslation();

    const [needOtp, setNeedOtp] = useState(false);
    const [formdata, setFormdata] = useState<any>();

    const { login, user } = useAuth({
        onNeedOtp: () => {
            setNeedOtp(true);
        },
    });
    const [isLoading, setIsLoading] = useState(false);
    const [present] = useIonToast();
    const { IsDesktop } = useResponsive();

    const handleForgotPasswordClick = () => {
        if (onClose) {
            onClose();
        }
        if (onForgotPasswordClick) {
            onForgotPasswordClick();
        }
    };

    const openOnBoardingWindowForTiktok = async () => {
        const url = await getTiktokUrl(isPlatform('cordova') ? 'mobile' : 'desktop');
        window.location.replace(url);
    };

    const openOnBoardingWindowForFacebook = async () => {
        const url = await getFacebookUrl(isPlatform('cordova') ? 'mobile' : 'desktop');
        window.location.replace(url);
    };

    const schema = yup
        .object({
            email: yup
                .string()
                .required(t('email_required'))
                .matches(emailRegex, { message: t('email_invalid') }),
            password: yup.string().required(t('password_required')),
        })
        .required();

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm<LoginFormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: LoginFormValues) => {
        setIsLoading(true);
        try {
            setFormdata(data);
            await login(data);
        } catch (e: any) {
            console.error(e);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            present({ message: e.data.message, duration: 5000 });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (onDone && !!user?.id) {
            onDone();
        }
    }, [onDone, user]);

    const btnNextDisabled = !watch('password') || !watch('email');

    if (needOtp) {
        return (
            <div className={`${className || ''} mx-auto`}>
                <OtpForm
                    email={formdata?.email}
                    onComplete={() => {
                        onSubmit(formdata);
                    }}
                />
            </div>
        );
    }

    return (
        <div className={`mx-auto ${className || ''}`}>
            <div className="text-center">
                <img
                    src={loginIllustration}
                    className="mb-[40px] mx-auto"
                    alt="login illustration"
                />
            </div>

            <h1 className="my-4 typo-h1 font-bold">{t('login')}</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading && <IonLoading isOpen />}

                <Input
                    hasError={!!errors.email}
                    placeholder={t('email')}
                    {...register('email')}
                />
                <ErrorMessage
                    errors={errors}
                    name="email"
                    as={<InputError />}
                />

                <Input
                    hasError={!!errors.password}
                    type="password"
                    placeholder={t('password')}
                    {...register('password')}
                />
                <ErrorMessage
                    errors={errors}
                    name="password"
                    as={<InputError />}
                />

                <Button
                    type="submit"
                    className="my-[45px] md:my-[15px]"
                    value={t('next')}
                    forceHoverEffectActive={!btnNextDisabled}
                    disabled={btnNextDisabled}
                />
            </form>

            <div className="text-center mt-[15px]">
                <div>
                    <IonRouterLink
                        routerDirection={onForgotPasswordClick ? undefined : 'forward'}
                        routerLink={onForgotPasswordClick ? undefined : '/reset-password'}
                        className="text-white bg-transparent typo-b2 regular underline mb-4 block cursor-pointer"
                        onClick={handleForgotPasswordClick}
                    >
                        {t('forgot_password')}
                    </IonRouterLink>
                </div>

                <div className="divider mb-[15px] mt-[45px] typo-b1 semibold">{t('create_account_with')}</div>
                <div className="mb-[40px] flex justify-center gap-[16px]">
                    {!isPlatform('cordova') && (
                        <>
                            <NetworkLoginButton onClick={openOnBoardingWindowForFacebook}>
                                <FacebookIcon />
                            </NetworkLoginButton>
                            {/* <NetworkLoginButton onClick={() => { }}>
                            <InstaIcon />
                        </NetworkLoginButton> */}
                            <NetworkLoginButton onClick={openOnBoardingWindowForTiktok}>
                                <TiktokIcon />
                            </NetworkLoginButton>
                        </>
                    )}
                </div>
                <IonRouterLink
                    routerDirection="forward"
                    routerLink={onSignupClick ? undefined : '/signup'}
                    className="eldo-button grid place-items-center cursor-pointer md:bg-[#282828] typo-b1 regular"
                    onClick={onSignupClick || onClose}
                >
                    {t('email')}
                </IonRouterLink>
            </div>
        </div>
    );
}
