import {
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonRow,
    IonTextarea,
    useIonToast,
} from '@ionic/react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import CardWithRadioButton from 'components/Cards/CardWithRadioButton';
import Button from 'components/Button';
import * as yup from 'yup';
import { dateRegex, emailRegex, phoneRegex } from 'lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { sendTicketBourse } from 'lib/ApiService';
import Title from 'components/Title';
import InputError from 'components/InputError';
import PageLayout from 'components/PageLayout';
import useResponsive from 'features/responsive/responsive';
import useAuth from 'features/auth/useAuth';
import { useEffect } from 'react';
import PhoneInput from 'components/PhoneInput';
import { DatePicker } from '@mui/x-date-pickers';
import { TOAST_DURATION } from '../constants';

type FormValues = {
    name: string;
    phone: string;
    email: string;
    title: string;
    description: string;
    date: string;
    user_id: string;
    type: string;
};

export default function TicketBourseForm() {
    const { t } = useTranslation();
    const [present] = useIonToast();
    const history = useHistory();
    const { user } = useAuth();

    const { IsMobile } = useResponsive();

    const schema = yup
        .object({
            name: yup.string().required(t('form.name_required')),
            phone: yup.string().required(t('form.phone_required')),
            email: yup
                .string()
                .required(t('form.email_required'))
                .matches(emailRegex, { message: t('form.email_invalid') }),
            title: yup.string().required(t('form.title_required')),
            description: yup.string().required(t('description_required')),
            date: yup.string().required(t('birthday_required')),
            type: yup.string().required(t('form.type_required')),
        })
        .required();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { isValid, errors },
        control,
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!user) {
            history.push('/events/ticket-bourse');
        }
    }, [user]);

    const onSubmit = async (data: FormValues) => {
        const payload = {
            name: data.name,
            phone: data.phone,
            email: data.email,
            title: data.title,
            description: data.description,
            date: moment(new Date(data.date), 'DD/MM/YYYY').format('YYYY-MM-DD'),
            user_id: data.user_id,
            type: data.type,
        };
        try {
            payload.user_id = user?.id.toString() ?? '';
            const response = await sendTicketBourse(payload);
            if (response.ok) {
                present({ color: 'success', duration: TOAST_DURATION, message: t('ticketBourse.createdSuccessfully') });
                history.push('/events/ticket-bourse');
            }
        } catch (e: any) {
            const message: string = e.error || e.response?.data?.error || e.response?.message || e.message || 'Error';
            await present({ message, duration: TOAST_DURATION, position: 'top', color: 'danger' });
        }
    };

    function onDateChange(ev: any) {
        if (!ev) {
            return;
        }
        setValue('date', ev);
    }

    return (
        <PageLayout
            hasBackButton
            title={t('ticketBourse.title')}
        >
            <IonGrid
                fixed
                className="md:flex md:justify-center md:bg-[#141414]"
            >
                <div className="md:w-[350px]">
                    <div className="mb-blockInnerMobile md:mb-blockInner">
                        {!IsMobile ? (
                            <Title
                                main
                                title={t('ticketBourse.title')}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <IonRow>
                        <IonCol className="p-0 mb-4 md:mb-10">
                            <div className="relative">
                                <div className="absolute right-[-30px] md:hidden">
                                    <img
                                        src="./assets/img/team/Ornament-81.png"
                                        alt="ornament"
                                    />
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <IonRadioGroup>
                            <IonRow className="flex flex-row flex-wrap gap-[30px] mb-blockInnerMobile md:mb-blockInner">
                                <IonCol className="p-0 flex flex-row gap-[30px]">
                                    <CardWithRadioButton
                                        title={t('ticketBourse.buy')}
                                        value="buy"
                                        register={register('type')}
                                        className="h-[88px]"
                                    />
                                </IonCol>
                                <IonCol className="p-0 flex flex-row gap-[30px]">
                                    <CardWithRadioButton
                                        title={t('ticketBourse.sell')}
                                        value="sell"
                                        register={register('type')}
                                        className="h-[88px]"
                                    />
                                </IonCol>
                            </IonRow>
                        </IonRadioGroup>
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <IonItem className="game-input">
                                <IonLabel
                                    className="game-input"
                                    position="floating"
                                >
                                    {t('ticketBourse.name')}
                                </IonLabel>
                                <IonInput
                                    className="game-input"
                                    {...register('name')}
                                />
                            </IonItem>
                        </IonRow>
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <PhoneInput
                                placeholder={t('phone')}
                                {...register('phone')}
                                control={control}
                            />
                        </IonRow>
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <IonItem className="game-input">
                                <IonLabel
                                    className="game-input"
                                    position="floating"
                                >
                                    {t('ticketBourse.email')}
                                </IonLabel>
                                <IonInput
                                    className="game-input"
                                    {...register('email')}
                                />
                            </IonItem>
                        </IonRow>
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <DatePicker
                                onChange={(date) => onDateChange(date)}
                                format="DD/MM/YYYY"
                                label={t('date')}
                                value={watch('date')}
                                className="typo-b2 regular rounded-t-lg px-[15px] border-0 h-[54px] outline-0 bg-background-alt [&_.MuiFormLabel-root]:!text-white w-[100%] [&_.MuiSvgIcon-root]:!text-white [&_.MuiInputBase-input]:!text-white"
                            />
                        </IonRow>
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <IonItem className="game-input">
                                <IonLabel
                                    className="game-input"
                                    position="floating"
                                >
                                    {t('ticketBourse.nameOfEvent')}
                                </IonLabel>
                                <IonInput
                                    className="game-input"
                                    {...register('title')}
                                />
                            </IonItem>
                        </IonRow>
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <IonItem
                                className="game-input"
                                style={{ height: 180 }}
                            >
                                <IonLabel
                                    className="game-input"
                                    position="floating"
                                >
                                    {t('ticketBourse.moreInformation')}
                                </IonLabel>
                                <IonTextarea
                                    className="game-input"
                                    style={{ minHeight: 180, padding: IsMobile ? 10 : 0 }}
                                    {...register('description')}
                                />
                            </IonItem>
                        </IonRow>
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <div className="">
                                <ErrorMessage
                                    errors={errors}
                                    name="type"
                                    as={<InputError />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    as={<InputError />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="phone"
                                    as={<InputError />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    as={<InputError />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="date"
                                    as={<InputError />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="title"
                                    as={<InputError />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="phone"
                                    as={<InputError />}
                                />
                            </div>
                        </IonRow>
                        <div className="typo-h2 font-bold leading-[34px] w-fit items-center gap-[40px] mb-blockInnerMobile md:mb-blockInner">
                            {t('ticketBourse.important')}
                        </div>
                        <div className="w-fit items-center gap-[40px] mb-blockInnerMobile md:mb-blockInner">
                            {t('ticketBourse.importantText1')}
                        </div>
                        <div className="w-fit items-center gap-[40px] mb-blockInnerMobile md:mb-blockInner">
                            {t('ticketBourse.importantText2')}
                        </div>
                        <div className="w-fit items-center gap-[40px] mb-blockInnerMobile md:mb-blockInner">
                            {t('ticketBourse.importantText3')}
                        </div>

                        <IonRow className="my-blockMobile md:my-block">
                            <Button
                                value={t('ticketBourse.send')}
                                className={`bg-[#8A8A8A] ${
                                    isValid ? '!text-[#141414] bg-white' : 'text-[#555555]'
                                } border-0`}
                                type="submit"
                                disabled={false}
                            />
                        </IonRow>
                    </form>
                </div>
            </IonGrid>
        </PageLayout>
    );
}
